.tippy-box {
  border-radius: var(--radius);
  box-shadow: 0 0 0 0 rgb(0 0 0) !important;
  background-color: transparent !important;
  border: none !important;
}

.tippy-arrow {
  display: none !important;
}
