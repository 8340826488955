/* Don't wrap this in .tiptap; This would mean it only applies to the interactive editor, but not the rendered html. We want both. */
.editor-callout-node {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem; /* gap-2 */
  border-radius: 0.25rem; /* rounded-sm */
  background-color: hsl(var(--foreground) / 0.05); /* bg-foreground/5 */
  padding: 1rem; /* p-4 */

  /* Icon container */
  .callout-icon {
    flex-shrink: 0;
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem;
    height: 2rem; /* h-8 */
    width: 2rem; /* w-8 */

    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  /* Content container */
  .callout-content {
    flex: 1;
    min-width: 0; /* This prevents flex child from overflowing */
    padding-top: 0.25rem; /* pt-1 */
    overflow-wrap: break-word;
  }
}
